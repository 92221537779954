import { ICreateUser, ILogin, ILoginResponse, IResetPass, IUser } from "@/interfaces";
import { Api } from "@/provider";

export const UserService = {
  async createUser(payload: ICreateUser) {
    try {
      const { data } = await Api.post<IUser>("/users", payload);
      return data;
    } catch (error) {
      console.error(error);
    }
  },

  async getUser(id: number | string) {
    try {
      const { data } = await Api.get<IUser>(`/users/${id}`);
      return data;
    } catch (error) {
      console.error(error);
    }
  },

  async updateUser(payload: any, id: string | number) {
    try {
      const { data } = await Api.patch<IUser>(`/users/${id}`, payload);
      return data;
    } catch (error) {
      console.error(error);
    }
  },

  async login(payload: ILogin) {
    try {
      const { data } = await Api.post<ILoginResponse>("/auth/login", payload);
      return data;
    } catch (error) {
      console.error(error);
    }
  },

  async getPassReset(email: string, redirect_link: string) {
    const { data } = await Api.post(`/users/request-password-reset`, {
      email,
      redirect_link,
    });
    return data;
  },

  async resetPassword(payload: IResetPass) {
    const { data } = await Api.post(`/users/reset-password`, payload);
    return data;
  },
};

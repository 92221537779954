"use client";
import Image from "next/image";
import Link from "next/link";
import { PiUserCircleDashed } from "react-icons/pi";
import { SiOpensea } from "react-icons/si";
import { MdOutlineSell } from "react-icons/md";
import { Button } from "./ui/button";
import { ScrollArea, ScrollBar } from "./ui/scroll-area";
import logo from "../../public/logo_2.png";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import { useAuth } from "@/context/AuthContext";
import { CiLogout } from "react-icons/ci";
import { Pixelify_Sans } from "next/font/google";
import { ConnectButton, useChainModal } from "@rainbow-me/rainbowkit";
import { StorageHelper } from "@/helpers/StorageHelper";
import { useRouter } from "next/navigation";
import { useDisconnect } from "wagmi";

const pixelify = Pixelify_Sans({
  weight: ["400", "700", "500"],
  preload: false,
});

export function Header() {
  const { push } = useRouter();
  const { user, setAddress, setToken, setUser } = useAuth();
  const { disconnect } = useDisconnect();
  const { removeItem } = StorageHelper;

  async function onLogout() {
    disconnect();
    removeItem("authToken");
    removeItem("userData");
    setToken("");
    setAddress("");
    setUser(null);
    push("/login");
  }

  return (
    <ScrollArea className="whitespace-nowrap rounded-md border-0">
      <nav
        className={`${pixelify.className} font-bold w-full bg-zinc-900 bg-opacity-30 backdrop-blur-lg fixed top-0 left-0 z-[9999999]`}
      >
        <div className="container mx-auto h-full">
          <div className="flex lg:flex-row flex-col items-center justify-between">
            <div className="flex items-center">
              <Link href="/">
                <Image
                  src={logo}
                  alt="Logo"
                  width={80}
                  height={50}
                  className="m-1 w-12"
                />
              </Link>
              <div className="ml-4 p-2">
                <Link href="https://wiki.farmine.land">
                  <div className="text-white hover:text-zinc-400 text-xs font-normal tracking-normal">
                    Explore
                  </div>
                  <div className="text-yellow-700 hover:text-yellow-800 text-sm font-bold tracking-normal">
                    The Wiki
                  </div>
                </Link>
              </div>
              <div className="p-2">
                <Link href="/">
                  <div className="text-white hover:text-zinc-400 text-xs font-normal tracking-normal">
                    Explore
                  </div>
                  <div className="text-yellow-700 hover:text-yellow-800 text-sm font-bold tracking-normal">
                    The Map
                  </div>
                </Link>
              </div>
              <div className="p-2">
                <Dialog>
                  <DialogTrigger asChild>
                    <div className="cursor-pointer">
                      <div className="text-white hover:text-zinc-400 text-xs font-normal tracking-normal">
                        See
                      </div>
                      <div className="text-yellow-700 hover:text-yellow-800 text-sm font-bold tracking-normal">
                        NFT Collections
                      </div>
                    </div>
                  </DialogTrigger>
                  <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                      <DialogTitle className="text-white text-base font-normal tracking-normal">
                        Farmine Land NFT collections:
                      </DialogTitle>
                    </DialogHeader>

                    <div className="grid gap-4 py-4">
                      <div className="grid grid-cols-4 items-center gap-4">
                        <Link href="https://opensea.io/collection/farmine-land-alphatester">
                          <Button className="bg-orange-500 hover:bg-orange-600/90 text-zinc-900 m-2 py-1 px-3 border-2 border-double border-zinc-950">
                            <SiOpensea
                              size={16}
                              color="#2563eb"
                              className="mr-1"
                            />
                            Alphatester
                          </Button>
                        </Link>
                      </div>
                      <div className="grid grid-cols-4 items-center gap-4">
                        <Link href="https://opensea.io/collection/farmine-land-dogs">
                          <Button className="bg-orange-500 hover:bg-orange-600/90 text-zinc-900 m-2 py-1 px-3 border-2 border-double border-zinc-950">
                            <SiOpensea
                              size={16}
                              color="#2563eb"
                              className="mr-1"
                            />
                            Dogs
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
              </div>
            </div>

            {!user ? (
              <div className="flex items-center space-x-4">
                <ConnectButton />
                <Link href="/login">
                  <Button className="bg-orange-500 hover:bg-orange-600/90 text-zinc-900 m-2 py-1 px-3 border-2 border-double border-zinc-950">
                    <PiUserCircleDashed size={16} className="mr-1" />
                    Login
                  </Button>
                </Link>

                <Link href="/register">
                  <Button className="bg-orange-500 hover:bg-orange-600/90 text-zinc-900 m-2 py-1 px-3 border-2 border-double border-zinc-950">
                    <PiUserCircleDashed size={16} className="mr-1" />
                    Register
                  </Button>
                </Link>
              </div>
            ) : (
              <div className="flex flex-col-reverse pb-4 lg:flex-row items-center justify-center gap-4">
                <ConnectButton />
                <div>
                  <Link href="/dashboard">
                    <Button className="bg-orange-500 hover:bg-orange-600/90 text-zinc-900 m-2 py-1 px-3 border-2 border-double border-zinc-950">
                      <PiUserCircleDashed size={16} className="mr-1" />
                      Dashboard
                    </Button>
                  </Link>
                  <Link href="/sales">
                    <Button className="bg-orange-500 hover:bg-orange-600/90 text-zinc-900 m-2 py-1 px-3 border-2 border-double border-zinc-950">
                      <MdOutlineSell size={16} className="mr-1" />
                      Sales
                    </Button>
                  </Link>
                  <Button
                    variant="outline"
                    size="icon"
                    className="border-none"
                    onClick={onLogout}
                  >
                    <CiLogout size={16} color="white" />
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
      <ScrollBar orientation="horizontal" />
    </ScrollArea>
  );
}

import Link from "next/link";
import { FaDiscord } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

export function Footer() {
  return (
    <div className="w-full p-4 bg-zinc-900 bg-opacity-30 backdrop-blur-lg bottom-0 left-0 z-50">
      <section className="container mx-auto px-4 flex flex-col md:flex-row md:justify-between items-center">
        <div className="flex space-x-4 mb-4 md:mb-0 text-white">
          <Link href="https://discord.com/invite/farmineland">
            <div className="border-2 border-solid border-sky-700 rounded-full p-2">
              <FaDiscord size={24} />
            </div>
          </Link>
          <Link href="https://x.com/Farmine_land">
            <div className="border-2 border-solid rounded-full p-2">
              <FaSquareXTwitter size={24} />
            </div>
          </Link>
        </div>

        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4">
          <Link href="/register">
            <div className="text-yellow-700 hover:text-yellow-800 text-sm font-bold tracking-normal">
              Registration
            </div>
          </Link>
          <Link href="https://wiki.farmine.land/">
            <div className="text-yellow-700 hover:text-yellow-800 text-sm font-bold tracking-normal">
              Information
            </div>
          </Link>
          <Link href="/login">
            <div className="text-yellow-700 hover:text-yellow-800 text-sm font-bold tracking-normal">
              Login
            </div>
          </Link>
        </div>
      </section>
    </div>
  );
}

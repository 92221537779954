"use client";
import { StorageHelper } from "@/helpers/StorageHelper";
import type { ILoginResponse, IUser } from "@/interfaces";
import { UserService } from "@/services/user.service";
import { usePathname, useRouter } from "next/navigation";
import {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  ReactNode,
} from "react";
import { ethers } from "ethers";
import { useAccount, useDisconnect } from "wagmi";

interface AuthContextType {
  token: string | null;
  setToken: (token: string) => void;
  user: IUser | null;
  setUser: (user: IUser | null) => void;
  address: string;
  setAddress: (address: string) => void;
  wallet: ethers.JsonRpcSigner | null;
  setWallet: (wallet: ethers.JsonRpcSigner) => void;
  provider: ethers.BrowserProvider | null;
  setProvider: (wallet: ethers.BrowserProvider) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const pathname = usePathname();
  const { address: userAddress } = useAccount();
  const { getItem, removeItem } = StorageHelper;
  const [wallet, setWallet] = useState<ethers.JsonRpcSigner | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [user, setUser] = useState<IUser | null>(null);
  const [address, setAddress] = useState("");
  const [provider, setProvider] = useState<ethers.BrowserProvider | null>(null);

  async function getData(userData: ILoginResponse) {
    const noAuthPaths = ["/login", "/", "/forgot-password", "/register", "/sales"];
    const noAuth = noAuthPaths.includes(pathname);

    if (!userData && !noAuth) {
      removeItem("authToken");
      removeItem("userData");
      setToken("");
      setAddress("");
      setUser(null);
      router.push("/login");
    }
    if (userData) {
      setToken(userData.token);
      const userId = userData.user.id;
      const user = await UserService.getUser(userId);
      setUser(user!);
    }
  }

  useEffect(() => {
    if (window.ethereum) {
      const provider = new ethers.BrowserProvider(window.ethereum);
      setProvider(provider);
      provider.getSigner().then((e) => setWallet(e));
      setAddress(userAddress!);

      const userData = getItem("userData");
      getData(userData);
    } else {
      console.error("Ethereum object not found.");
    }
  }, [pathname, userAddress]);

  const value = useMemo(
    () => ({
      token,
      setToken,
      setUser,
      user,
      address,
      setAddress,
      setWallet,
      wallet,
      setProvider,
      provider,
    }),
    [user, token]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
